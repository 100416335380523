import { isWithinInterval, parse, subHours } from 'date-fns'
import type { DeliveryTimeSlot } from '~/type/DeliveryTime'

export function timeExpirationCheck(slot: DeliveryTimeSlot) {
  const now = new Date()
  const adjustedNow = subHours(now, 1)

  const start = parse(slot.startTime, 'HH:mm', now)
  const end = parse(slot.endTime, 'HH:mm', now)

  const isInTimeRange = isWithinInterval(adjustedNow, { start, end })

  return isInTimeRange
}
