<script setup lang="ts">
import type { DeliveryType } from '~/type/DeliveryTime'

withDefaults(defineProps<{
  type?: 'error' | 'warning'
  deliveryType: DeliveryType
  note: string
}>(), {
  type: 'warning',
})

const emit = defineEmits<{
  (e: 'clickNote'): void
}>()
</script>

<template>
  <div
    class="delivery-type-note"
    :class="`delivery-type-note_${type}`"
  >
    <div class="delivery-type-note__arrow" :class="`delivery-type-note__arrow_${deliveryType}`" />
    <p>{{ note }}</p>
    <button
      v-if="type === 'warning'"
      class="delivery-type-note__button"
      @click="emit('clickNote')"
    >
      <img src="./asset/note.svg" alt="Подробнее">
    </button>
  </div>
</template>

<style lang="postcss" scoped>
.delivery-type-note {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  font-size: 12px;
  line-height: 17px;
  border-radius: 12px;

  &_warning {
    color: black;
    cursor: pointer;
    background-color: #FFF4E2
  }

  &_error {
    color: #E30613;
    background-color: #FFF0F1;
  }

  &__arrow {
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF4E2;
    border-left: 10px solid transparent;
    transform: translateX(-50%);

    &_delayed {
      left: 75%;
    }

    &_express {
      left: 25%;
    }
  }

  &__button {
    margin-left: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }

  @media (min-width: 1000px) {

    &_warning {
      cursor: initial;
    }

    &__button {
      display: none;
    }
  }
}
</style>
